import React from "react";
import { FaSalesforce, FaCode, FaLaptopCode } from "react-icons/fa";
import ContactPage from "./contact";
import ServicesSection from "./services";
import { useEffect } from "react";

const HomePage = () => {
  const handleSchedule = () => {
    window.open(
      "https://calendly.com/cooper-tintech/1-on-1-software-discovery-session-crafting-your-vision",
      "_blank"
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home-page">
      <section className="hero">
        <div className="hero-content">
          <h1>Strategies for Business Growth</h1>
          <p>
            Hi, I'm Cooper. I'm a software engineer and business consultant. I
            help businesses grow with technology.
          </p>
          <div className="hero-skills">
            <span>
              <FaSalesforce /> Salesforce Solutions
            </span>
            <span>
              <FaLaptopCode /> Custom Apps
            </span>
            <span>
              <FaCode /> Technology Strategy
            </span>
          </div>
          <button onClick={handleSchedule}>Let's Work Together</button>
        </div>
        <div className="hero-image">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/TingeyFam_BH-048.jpg?alt=media&token=55e44df0-fc15-4740-b89d-3bce4c87674a"
            alt="Cooper - Software Engineer"
          />
        </div>
      </section>

      <ServicesSection />

      <ContactPage id="contact" />
    </div>
  );
};

export default HomePage;
