import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./navigation/navbar";
import Footer from "./navigation/footer";
import TeamPage from "./components/team";
import HomePage from "./components/homepage";
import SalesforcePage from "./components/salesforcePage";
import CustomAppsPage from "./components/customAppsPage";
import ConsultingPage from "./components/consultingPage";
import Portfolio from "./components/portfolio";
// import BusinessTechReviewPage from "./components/businessTechReviewPage";

const projects = [
  {
    title: "Ellucian - CampusLogic",
    categories: ["Web App", "Database", "Website"],
    description: "Led the development of student financial services software that streamlines financial aid processes. The platform helps millions of students navigate college financing through automated scholarship matching, digital award letters, and simplified FAFSA completion tools. Contributed to making higher education more accessible through technology.",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/ellucian-removebg-preview.webp?alt=media&token=064626f3-5107-4b75-90a1-59baf852cbee",
    liveLink: "https://www.ellucian.com/",
  },
  {
    title: "LA Chamber of Commerce",
    categories: ["Salesforce"],
    description: "Optimized Salesforce operations for the Los Angeles Chamber of Commerce by implementing automated workflows and refining data management processes. Enhanced member engagement tracking and streamlined internal operations through custom flow development and systematic data organization.",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/LA-chamber_0.png?alt=media&token=6cfa28c3-0e74-4c0a-a95f-0f11035e97b1",
    liveLink: "https://lachamber.com/",
  },
  {
    title: "Church of Jesus Christ of Latter-day Saints",
    categories: ["Web App", "Database", "Consulting"],
    description: "Developed a sophisticated calendar application using React JS and advanced JavaScript. Created robust event management functionality allowing administrators to publish and manage calendar events across multiple ward websites. Implemented real-time updates and intuitive user interfaces for seamless event coordination.",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/church.jpg?alt=media&token=b7f9de34-7b0e-4422-b7c2-69b7c6819276",
    liveLink: "https://www.churchofjesuschrist.org/?lang=eng",
  },
  {
    title: "Porter Mountain Advisors",
    categories: ["Website", "Consulting"],
    description: "Designed and developed a professional website for a Mesa-based tax accounting firm using Wix. Provided comprehensive technical consulting to optimize their digital presence and streamline client interactions. Implemented custom solutions to enhance their online service delivery and client communication.",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/pma.jpg?alt=media&token=5f1b3845-2299-4cfb-9745-cc572c86dbb7",
    liveLink: "https://www.portermtnadvisors.com/",
  },
  {
    title: "NewTripIdeas",
    categories: ["Web App", "Database"],
    description: "Solo-developed an innovative AI-powered travel planning platform that generates personalized travel recommendations based on user preferences. Successfully grew the platform to serve 1,000+ monthly active users. Integrated cutting-edge AI technology to provide unique, tailored travel experiences while maintaining high user engagement.",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/palm-tree-logo-orange.jpg?alt=media&token=a9675687-e793-4e8c-a963-29b3a99d5287",
    liveLink: "https://newtripideas.com",
  },
  {
    title: "Intellipay",
    categories: ["Web App", "Database"],
    description: "Engineered an internal messaging system that revolutionized organizational communication. Seamlessly integrated with existing APIs to create a cohesive communication platform. The system continues to serve as a crucial tool for internal operations, demonstrating lasting impact and utility.",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/intellipay.png?alt=media&token=6ded4b86-c2ea-4aea-bebe-3429e941d2bf",
    liveLink: "https://intellipay.com/",
  },
  {
    title: "Wyssling Consulting",
    categories: ["Web App"],
    description: "Spearheaded the complete transformation of operations for the nation's leading solar panel engineering consulting firm. Developed a custom web application that centralized their entire business operations. Provided strategic technical consulting to enable autonomous growth on their customized platform.",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/wyssling.png?alt=media&token=0dedcdf5-3da8-42a1-b5fb-72eb92d6804b",
    liveLink: "https://www.wysslingconsulting.com/",
  },
  {
    title: "EPYPE",
    categories: ["Web App", "Database"],
    description: "Contributed to an innovative Provo-based startup delivering advanced marketing tools for businesses and individuals. Utilized React to develop responsive and intuitive interfaces, enabling users to effectively manage and optimize their marketing campaigns.",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/epype.jpeg?alt=media&token=0d3b78ec-6ef9-4bfc-a40c-ce26d8cb1685",
    liveLink: "https://www.epype.io/",
  },
  {
    title: "PlatPay Solutions",
    categories: ["Database"],
    description: "Managed critical financial data through advanced SQL implementations. Developed and optimized complex queries to ensure accurate financial reporting and data integrity. Demonstrated expertise in handling sensitive financial information while maintaining strict security protocols.",
    thumbnail: "https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/GWRuT8ZI_400x400.jpg?alt=media&token=36c8b56a-a1f2-44b0-b59e-ab7479601a38",
    liveLink: "https://platpay.com",
  },
  {
    title: "Software Technology Group",
    categories: ["Consulting", "Web App"],
    description: "Serving as a technology consultant since February 2024, focusing on developing advanced consulting methodologies and client relationship management. Leveraged this experience to establish an independent consulting practice, delivering high-value technical solutions across diverse industries.",
    thumbnail: "https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/stg-logo-light.png?alt=media&token=6ecacbe0-1e65-4690-9bbc-c25014b3c16f",
    liveLink: "https://stgconsulting.com/",
  }
];


function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="content">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/portfolio" element={<Portfolio projects={projects}/>} />
            <Route path="/about" element={<TeamPage />} />
            <Route path="/salesforce" element={<SalesforcePage/>}/>
            <Route path="/custom-apps" element={<CustomAppsPage/>}/>
            <Route path="/consulting" element={<ConsultingPage/>}/>
            {/* <Route path="/business-tech-review" element={<BusinessTechReviewPage/>}/> */}
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
