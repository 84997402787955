import { FaSearchDollar, FaChartLine, FaCheck } from "react-icons/fa";
import styles from "../styles/consultingPage.module.css";
import ContactPage from "./contact";

const ConsultingPage = () => {
  const handleSchedule = () => {
    window.open(
      "https://calendly.com/cooper-tintech/1-on-1-software-discovery-session-crafting-your-vision",
      "_blank"
    );
  };

  return (
    <div className={styles.techReviewPage}>
      <header className={styles.header}>
        <div className={styles.headerOverlay}></div>
        <div className={styles.headerContent}>
          <div className={styles.textContainer}>
            <h1>Business Tech Review</h1>
            <p>
              Running a growing <strong>service business</strong> is hard enough
              - let's get your tools sorted. I'll analyze everything from
              website SEO to client management and help you build a plan that
              makes sense.
            </p>
            <button onClick={handleSchedule} className={styles.primaryButton}>
              Schedule Your Review
            </button>
          </div>
        </div>
      </header>

      <section className={styles.services}>
        <div className={styles.container}>
          <h2>Choose Your Technology Assessment</h2>
          <div className={styles.servicesGrid}>
            {/* Small-Medium Business Card */}
            <div className={styles.serviceCard}>
              <div className={styles.serviceHeader}>
                <div>
                  <FaSearchDollar className={styles.serviceIcon} />
                  <h3> Small-Medium Business Review</h3>
                  <p className={styles.revenueBadge}>Annual Revenue: $0-3M</p>
                </div>
              </div>
              <p>
                Need help using technology to grow your business? We’ll take a
                look at your current setup and show you how to improve it. We
                specialize in working with businesses like HVAC, Plumbing,
                Electrical, and Cleaning. Whether it’s fixing your website or
                making your operations easier, we’ve got you covered.
              </p>
              <ul>
                <li>
                  <FaCheck /> Customer Relationship Management System
                </li>
                <li>
                  <FaCheck /> Website Performance & SEO Strategy
                </li>
                <li>
                  <FaCheck /> Google Business Profile
                </li>
                <li>
                  <FaCheck /> Social Media Strategy & Online Presence
                </li>
              </ul>
              <button onClick={handleSchedule} className={styles.primaryButton}>
                Book Small Business Review
              </button>
            </div>

            {/* Large Business Card */}
            <div className={styles.serviceCard}>
              <div className={styles.serviceHeader}>
                <div>
                  <FaChartLine className={styles.serviceIcon} />
                  <h3>Enterprise Technology Review</h3>
                  <p className={styles.revenueBadge}>Annual Revenue: $3M+</p>
                </div>
              </div>
              <p>
                Ideal for established businesses seeking to optimize and scale
                their technology infrastructure. We provide an in-depth review
                of your entire system, identifying opportunities to improve
                efficiency, streamline operations, and support sustainable
                growth.
              </p>
              <ul>
                <li>
                  <FaCheck /> Infrastructure & Systems Assessment
                </li>
                <li>
                  <FaCheck /> Security & Compliance Review
                </li>
                <li>
                  <FaCheck /> Digital Transformation Strategy
                </li>
                <li>
                  <FaCheck /> AI & Automation Opportunities
                </li>
              </ul>
              <button onClick={handleSchedule} className={styles.primaryButton}>
                Book Enterprise Review
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.expertise}>
        <div className={styles.container}>
          <h2>Why Choose Our Technology Review?</h2>
          <div className={styles.expertiseGrid}>
            <div className={styles.expertiseCard}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/businessreview2.webp?alt=media&token=40c07b67-e973-47b6-8ab9-e54a6dba2da6"
                alt="Tailored Solutions"
                className={styles.expertiseImage}
              />
              <h3>Tailored Solutions</h3>
              <p>
                Get personalized recommendations that match your business size,
                industry, and growth objectives
              </p>
            </div>
            <div className={styles.expertiseCard}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/businessreview4.webp?alt=media&token=19c7dc95-0549-4823-b3c6-575e9b731e23"
                alt="Expert Analysis"
                className={styles.expertiseImage}
              />
              <h3>Expert Analysis</h3>
              <p>
                Benefit from our deep technical expertise and years of
                experience across multiple industries
              </p>
            </div>
            <div className={styles.expertiseCard}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/businessreview1.png?alt=media&token=d29d6336-f390-4dd7-a218-56adb809bc3c"
                alt="Clear Action Plan"
                className={styles.expertiseImage}
              />
              <h3>Clear Action Plan</h3>
              <p>
                Receive a detailed roadmap with prioritized recommendations and
                implementation strategies
              </p>
            </div>
          </div>
        </div>
      </section>

      <ContactPage id="contact" />
    </div>
  );
};

export default ConsultingPage;
